<template>
  <div class="w-full h-auto py-6" style="width: 276px">
    <div class="w-full flex flex-wrap justify-start items-center gap-3">
      <div
        v-for="(color, index) in colors"
        :key="index"
        :style="{ backgroundColor: color }"
        :class="{ selected: selectedColor === color }"
        @click="selectColor(color)"
        class="color-box w-6 h-6 rounded-full"
      ></div>
    </div>

    <p class="text-xs mt-4 mb-1">Custom Color:</p>

    <div class="w-full h-10 flex gap-1 relative">
      <!-- Hidden color picker -->
      <input
        type="color"
        v-model="hexColor"
        @input="hexColor = $event.target.value"
        ref="colorPicker"
        width="0"
        height="0"
        style="position: absolute; left: 0; bottom: 20; opacity: 0"
      />

      <input
        type="text"
        v-model="customColor"
        @keyup.enter="handleKeyUpEvent"
        @click="triggerColorPicker"
        :placeholder="inputPlaceHolder"
        class="w-full h-full placeholder-romanSilver pl-3 text-sm rounded-md border-solid border-2 border-romanSilver outline-none"
      />

      <input
        v-if="perPlaceHolder"
        type="number"
        :placeholder="perPlaceHolder"
        v-model.number="colorPercentage"
        @keyup.enter="handleKeyUpEvent"
        class="w-16 h-full placeholder-romanSilver text-center text-sm rounded-md border-solid border-2 border-romanSilver outline-none"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Swatches",
  props: {
    type: String,
    colors: Array,
    inputPlaceHolder: String,
    perPlaceHolder: String,
    selectedColor: String,
  },
  data() {
    return {
      hexColor: this.selectedColor,
      customColor: this.selectedColor,
      colorPercentage: 0,
    };
  },

  watch: {
    customColor(newVal, oldVal) {
      if (this.validateInputHexColor(newVal)) {
        this.hexColor = newVal;
      }
    },
    hexColor(newVal) {
      this.customColor = newVal;
    },
  },

  methods: {
    triggerColorPicker() {
      this.$refs.colorPicker.click();
    },

    async selectColor(color) {
      try {
        await this.$handlePrivilege("appearanceSetting", "changeBannerColours");
        this.$emit("update-appearance", color, this.type);
      } catch (error) {
        return this.$toasted.error(
          "You do not have permission to perform this task",
          {
            duration: 5000,
          }
        );
      }
    },

    addNewCustomColor(newColor) {
      if (!this.colors.includes(newColor)) {
        this.colors.push(newColor);
      }
      this.selectColor(newColor);
    },

    validateInputKnownName() {
      const cssColor = new Option().style;
      cssColor.color = this.customColor;
      let validName = cssColor.color !== "";

      return validName;
    },

    validateInputHexColor() {
      const hexColorRegex =
        /^#(?:[0-9a-fA-F]{3}){1,2}$|^#(?:[0-9a-fA-F]{4}){1,2}$/;

      return hexColorRegex.test(this.customColor);
    },

    validateHexPercentage() {
      if (!this.validateInputHexColor()) {
        return this.$toasted.error(
          "Invalid color. Please enter a proper hex code.",
          {
            duration: 6000,
          }
        );
      }
      if (this.colorPercentage < -100 || this.colorPercentage > 100) {
        this.colorPercentage = 0;
        return this.$toasted.error(
          "Invalid percentage. Please enter a value between -100 and 100.",
          {
            duration: 6000,
          }
        );
      }

      const adjustedColor = this.changeInputColorBrightness(
        this.customColor,
        this.colorPercentage
      );
      this.addNewCustomColor(adjustedColor);
      this.customColor = "";
    },

    changeInputColorBrightness(hexColor, percent) {
      // Convert hex to RGB
      let r = parseInt(hexColor.slice(1, 3), 16);
      let g = parseInt(hexColor.slice(3, 5), 16);
      let b = parseInt(hexColor.slice(5, 7), 16);

      // Calculate adjustment
      let t = percent < 0 ? 0 : 255;
      let p = percent < 0 ? percent * -1 : percent;

      // Adjust and clamp values
      r = Math.round(((t - r) * p) / 100 + r);
      g = Math.round(((t - g) * p) / 100 + g);
      b = Math.round(((t - b) * p) / 100 + b);

      r = Math.max(0, Math.min(255, r));
      g = Math.max(0, Math.min(255, g));
      b = Math.max(0, Math.min(255, b));

      // Convert RGB back to hex
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    validateHexKnownNameColor() {
      if (!this.validateInputHexColor() && !this.validateInputKnownName()) {
        return this.$toasted.error(
          "Invalid color. Please enter a proper hex code or a known color name.",
          {
            duration: 6000,
          }
        );
      }
      const newColor = this.customColor;
      this.addNewCustomColor(newColor);
      this.customColor = "";
    },

    handleKeyUpEvent() {
      if (this.perPlaceHolder) {
        this.validateHexPercentage();
      } else {
        this.validateHexKnownNameColor();
      }
    },
  },
};
</script>

<style scoped>
.color-box {
  cursor: pointer;
  border: 2px solid white;
}

.selected {
  box-shadow: 0px 0px 0px 2px #00000040;
}
</style>
